import React from "react";
import Collection from "./Collection";
import Service from "./CollectionService";
import LastestCollection from "./Slider/LatestCollection";
import Shopping from "./ShopingService";
import TopProducts from "./TopProduct";
import CollectionSlider from "./Slider/CollectionSlider";
import Shoping from "./Shopping";
import FollowUs from "./FollowUs";
import Defaultgrid from "Pages/Product/Grid/Default";

const Home = () => {
  document.title = "Redokslab B2B Test Site";

  return (
    <React.Fragment>
      {/* <Collection />
      <Service />
      <TopProducts />
      <Shopping />
      <LastestCollection />
      <CollectionSlider />
      <Shoping />
      <FollowUs /> */}
      <Defaultgrid />
    </React.Fragment>
  );
};

export default Home;
