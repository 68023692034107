import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import FeaturesNoImg1 from "assets/images/ecommerce/features/feature-no-img-1.png";
import FeaturesNoImg2 from "assets/images/ecommerce/features/feature-no-img-2.png";
import FeaturesNoImg3 from "assets/images/ecommerce/features/feature-no-img-3.png";
import CatalogBannerImage from "assets/images/ecommerce/features/catalog-banner-bg.png";
import Thermo from "assets/images/ecommerce/features/thermo-scientific.png";
import EmailClothe from "Pages/Catalog/EmailClothe";
import { CommonService } from "Components/CommonService";
import Index from "Components/Index";

const Defaultgrid = () => {
  return (
    <React.Fragment>
      <section className="section pb-0 mt-4">
        <Container fluid>
          <Row className="g-2">
            <Col lg={7}>
              <Link
                to="#"
                className="product-banner-1 mt-4 mt-lg-0 rounded overflow-hidden d-block"
              >
                <Image
                  src={FeaturesNoImg1}
                  className="w-100 object-fit-cover "
                  rounded
                  alt=""
                  style={{ maxHeight: "480px" }}
                />
                <div className="product-content text-dark p-3 ps-5">
                  <Image src={Thermo} className="" rounded alt="" />
                  <h1 className="display-5 fw fw-bolder text-dark ff-primary">
                    Ultimate 3000 UHPLC System
                  </h1>
                  <span className="fs-24 fw-semibold ff-primary ">
                    Smart Flow Teknolojisi ile Daha Hassas ve Doğru Akış
                  </span>
                  <div className="product-bttn fs-16 fw-bold mt-4 ff-primary">
                    Ürün Detaylarını Gör
                  </div>
                </div>
              </Link>
            </Col>
            <Col lg={5}>
              <Row className="g-2">
                <Col lg={12}>
                  <Link
                    to="#"
                    className="product-banner-1 mt-4 mt-lg-0 rounded overflow-hidden d-block"
                  >
                    <Image
                      src={FeaturesNoImg2}
                      className="w-100 object-fit-cover"
                      alt=""
                      style={{ maxHeight: "236px" }}
                      rounded
                    />
                    <div className="product-content-2 p-3 ps-5 d-flex  ">
                      <div className="content-div">
                        {" "}
                        <h1 className=" fs-24  fw-bolder text-dark ff-primary">
                          Ultimate 3000 UHPLC System
                        </h1>
                        <span className=" text-dark fs-16 fw-semibold ff-primary ">
                          Smart Flow Teknolojisi ile Daha Hassas ve Doğru Akış
                        </span>
                        <div className="product-bttn fs-14 fw-bold mt-4 ff-primary">
                          Ürün Detaylarını Gör
                        </div>
                      </div>
                      <div className="d-none d-sm-flex h-100 ">
                        <Image src={Thermo} className="" rounded alt="" />
                      </div>
                    </div>
                  </Link>
                </Col>
                <Col lg={12}>
                  <Link
                    to="#"
                    className="product-banner-1 mt-4 mt-lg-0 rounded overflow-hidden d-block"
                  >
                    <Image
                      src={FeaturesNoImg3}
                      className="w-100 object-fit-cover"
                      alt=""
                      style={{ maxHeight: "236px" }}
                      rounded
                    />
                    <div className="product-content-2 p-3 ps-5 d-flex  ">
                      <div className="content-div">
                        {" "}
                        <h1 className=" fs-24  fw-bolder text-dark ff-primary">
                          Ultimate 3000 UHPLC System
                        </h1>
                        <span className=" text-dark fs-16 fw-semibold ff-primary ">
                          Smart Flow Teknolojisi ile Daha Hassas ve Doğru Akış
                        </span>
                        <div className="product-bttn fs-14 fw-bold mt-4 ff-primary">
                          Ürün Detaylarını Gör
                        </div>
                      </div>
                      <div className="d-none d-sm-flex h-100 ">
                        <Image src={Thermo} className="" rounded alt="" />
                      </div>
                    </div>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="position-relative section">
        <Container>
          <div className="ecommerce-product gap-4">
            <Index
              name="sidebar small-sidebar flex-shrink-0"
              cxxl="4"
              clg="4"
              cmd="6"
              cheight="200px"
            />
          </div>
        </Container>
      </section>
      {/* <EmailClothe /> */}
      <section className="catalog-banner-section position-relative">
        {" "}
        <Image
          src={CatalogBannerImage}
          className="w-100 object-fit-cover"
          rounded
          alt=""
          style={{ maxHeight: "480px" }}
        />{" "}
        <Container>
          <div className="catalog-banner-content">
            <h2 className=" fw-bolder" style={{ maxWidth: "290px" }}>
              Analitik Cihaz ve Sarf Malzeme Satışı
            </h2>
            <p>
              İlaç ve kozmetik araştırmaları, keşif ve üretim aşamalarını
              kapsayan yenilikçi, analitik teknolojiler ve çözüm önerilerimiz
            </p>
            <div className="product-bttn fs-14 fw-bold mt-4 ff-primary">
              Satış Ekibimizle Tanışın
            </div>
          </div>
        </Container>
      </section>
      <section className="mt-5 mb-4">
        <Container>
          <div className="brands-banner">
            <h3>Markalar</h3>
            <Image src={Thermo} className="" rounded alt="" />
          </div>
        </Container>
      </section>

      <CommonService />
    </React.Fragment>
  );
};

export default Defaultgrid;
