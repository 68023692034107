export const faqquetion = [
    {
        id:1,
        icone: 'bi bi-box-seam',
        title: 'Order'
    },
    {
        id:2,
        icone: 'bi bi-cash-coin',
        title: 'Payments'
    },
    {
        id:3,
        icone: 'bi bi-truck',
        title: 'Delivery'
    },
    {
        id:4,
        icone: 'bi bi-bag-dash',
        title: 'Returns'
    },
]