// pickdata Images
import img1 from "assets/images/fashion/product/img-01.png"
import img2 from "assets/images/fashion/product/img-02.png"
import img3 from "assets/images/fashion/product/img-03.png"
import img4 from "assets/images/fashion/product/img-04.png"
import img5 from "assets/images/fashion/product/img-05.png"
import img6 from "assets/images/fashion/product/img-06.png"
import img7 from "assets/images/fashion/product/img-07.png"
import img8 from "assets/images/fashion/product/img-08.png"
import img9 from "assets/images/fashion/product/img-09.png"
import img10 from "assets/images/fashion/product/img-10.png"
import img11 from "assets/images/fashion/product/img-11.png"
import img12 from "assets/images/fashion/product/img-12.png"
import img13 from "assets/images/fashion/product/img-13.png"
import img14 from "assets/images/fashion/product/img-14.png"
import img15 from "assets/images/fashion/product/img-15.png"
import img17 from "assets/images/fashion/product/img-17.png"
import img18 from "assets/images/fashion/product/img-18.png"

import image6 from "assets/images/products/img-6.png"
import image19 from "assets/images/products/img-19.png"


import insta1 from "assets/images/instgram/img-01.jpg"
import insta2 from "assets/images/instgram/img-02.jpg"
import insta3 from "assets/images/instgram/img-03.jpg"
import insta4 from "assets/images/instgram/img-04.jpg"
import insta5 from "assets/images/instgram/img-05.jpg"
import insta6 from "assets/images/instgram/img-06.jpg"

const pickdata = [
    {
        id:1,
        img1:img15,
        img2:img14,
        info:"Urbanic Women Fit And Flare Black Dress",
        price1:"$97.49",
        price2:"$265.37"
    },
    {
        id:2,
        img1:img13,
        img2:img11,
        info:"Imported Fabulous Women Top And Bottom Set",
        price1:"$59.99",
        price2:"$249.99",
        color:"yellow",
        size:"M"
    },
    {
        id:3,
        img1:img2,
        img2:img9,
        info:"Like Style Women pink Shoulder Bag",
        price1:"$97.49",
        price2:"$265.37"
    },
    {
        id:4,
        img1:img9,
        img2:img2,
        info:"Elanhood Full Sleeve Solid Women Jacket",
        // price1:"$97.49",
        price2:"$249.99"
    },
    {
        id:5,
        img1:img11,
        img2:img4,
        info:"Nike MAG Back To The Future",
        price1:"$120.32",
        price2:"$348.24"
    },
    {
        id:6,
        img1:img8,
        img2:img10,
        info:"Girls Printed Shorts",
        // price1:"$97.49",
        price2:"$149.57"
    },
]

const choosedata = [
    {
        id:1,
        header:"Products Quality",
        info:"Product quality refers to how well a product satisfies customer needs, serves its purpose and meets industry standards. ",
        icon:"bi bi-shield-check"
    },
    {
        id:2,
        header:"Fast & Free Shipping",
        info:"The cheapest way to ship a package will depend on factors such as package size and weight, as well as distance and delivery speed. ",
        icon:"bi bi-truck"
    },
    {
        id:3,
        header:"Payment Security",
        info:"Payment security refers to providing rules, regulations, and security measures to protect a customer or partner's privacy. ",
        icon:"bi bi-cash-coin"
    },
    {
        id:4,
        header:"Global Warehouse",
        info:"An international warehouse is a warehouse that's set up internationally, typically located in a global trade Houston, or Hong Kong. ",
        icon:"bi bi-house-door"
    },
]

const newarrivaldata = [
    {
        id:1,
        pic1: img2,
        pic2: img17,
        heading:"URBAN Men Winter Stylish Jacket",
        price:87.99,
        btn1:"Blue",
        btn2:"Yellow",
        btn3:"Success",
    },
    {
        id:2,
        pic1: img5,
        pic2: img1,
        heading:"Elanhood Full Sleeve Solid Women Jacket",
        price:120.32,
        price2:"$348.24",
        btn4:"Gray",
        btn5:"Cyan",
    },
    {
        id:3,
        pic1: image6,
        pic2: img3,
        heading:"American Tourister Blue Polyester Backpack",
        price:64.99,
        btn1:"Blue",
        btn6:"Red",
    },
    {
        id:4,
        pic1: image19,
        pic2: img11,
        heading:"Nike MAG Back to the Future",
        price:149.57,
    },
    {
        id:5,
        pic1: img8,
        pic2: img18,
        heading:"Girls Printed Shorts",
        price:124.64,
        btn3:"Success",
        btn5:"Cyan",
    }, 
    {
        id:6,
        pic1: img12,
        pic2: img9,
        heading:"Stylish Collar Full Sleeve T-shirt",
        price:241.99,
        price2:"$119.99",
        btn1:"Blue",
        btn3:"Success",
    },
    {
        id:7,
        pic1: img11,
        pic2: img13,
        heading:"Style Women A-line dark Blue Dress",
        price:120.32,
        btn1:"Blue",
        btn4:"Gray",
        btn5:"Cyan",
    },
    {
        id:8,
        pic1: img13,
        pic2: img11,
        heading:"Imported Fabulous Women Top And Bottom Set",
        price: 59.99,
        price2:"$249.99",
        btn6:"Red",
        btn2:"Yellow",
    },
    {
        id:9,
        pic1: img15,
        pic2: img14,
        heading:"Urbanic Women Fit And Flare Black Dress",
        price: 97.49,
        price2:"$265.37",
        btn3:"Success",
    },
    {
        id:10,
        pic1: img1,
        pic2: img9,
        heading:"Like Style Women pink Shoulder Bag",
        price:249.99,
        btn6:"Red",
        btn1:"Blue",
    },
]

const subarrivaldata = [

    {
        id:1,
        pic1: img5,
        pic2: img1,
        heading:"Elanhood Full Sleeve Solid Women Jacket",
        price:120.32,
        btn4:"Gray",
        btn5:"Cyan",
    },
    {
        id:2,
        pic1: img6,
        pic2: img3,
        heading:"American Tourister Blue Polyester Backpack",
        price:64.99,
        btn1:"Blue",
        btn6:"Red",
    },
    {
        id:3,
        pic1: img7,
        pic2: img4,
        heading:"Nike MAG Back to the Future",
        price:149.57,
    },
    {
        id:4,
        pic1: img1,
        pic2: img3,
        heading:"Fancy Clothing Casual Solid Women Pink Top",
        price:64.80,
        btn6:"Red",
        btn2:"Yellow",
    },
    {
        id:5,
        pic1: img2,
        pic2: img1,
        heading:"URBAN Men Winter Stylish Jacket",
        price:87.99,
        price2:"$199.99",
        btn1:"Blue",
        btn2:"Yellow",
        btn3:"Success",
    }
]

const instadata = [
    {
        id:1,
        image: insta1,
        header:"Women Fashion",
        body: "One or two brand names can look classy, but too many can detract from the sophistication you're going for.",
        position:"right"
    },
    {
        id:2,
        image: insta2,
        header:"Unique Fashion",
        body: "A capsule collection is essentially a condensed version of a designer's vision",
        position:"right"
    },
    {
        id:3,
        image: insta3,
        header:"Men's wear",
        body: "Yours may consist of traditional pieces, such as tops, bottoms, and outerwear, or focus on a single product.",
        position:"right"
    },
    {
        id:4,
        image: insta4,
        header:"Sport wear",
        body: "Typical sport-specific garments include tracksuits, shorts, T-shirts and polo shirts.",
        position:"right"
    },
    {
        id:5,
        image: insta5,
        header:"Women Fashion",
        body: "One or two brand names can look classy, but too many can detract from the sophistication you're going for.",
        position:"right"
    },
    {
        id:6,
        image: insta6,
        header:"Footwear",
        body: "Footwear refers to garments worn on the feet, which typically serves the purpose of protection against adversities.",
        position:"left"
    },
]

export { pickdata, choosedata, newarrivaldata, instadata , subarrivaldata }