import avatar1 from "assets/images/users/avatar-1.jpg";
import avatar2 from "assets/images/users/avatar-2.jpg";
import avatar3 from "assets/images/users/avatar-3.jpg";
import avatar4 from "assets/images/users/avatar-4.jpg";
import avatar5 from "assets/images/users/avatar-6.jpg";


import watch1 from "assets/images/watch/products/img-01.png";
import watch2 from "assets/images/watch/products/img-02.png";
import watch3 from "assets/images/watch/products/img-03.png";
import watch4 from "assets/images/watch/products/img-04.png";
import watch5 from "assets/images/watch/products/img-06.png";
import watch6 from "assets/images/watch/products/img-07.png";
import watch7 from "assets/images/watch/products/img-08.png";
import watch8 from "assets/images/watch/products/img-09.png";



import shoprry1 from "assets/images/instgram/img-01.jpg";
import shoprry2 from "assets/images/instgram/img-02.jpg";
import shoprry3 from "assets/images/instgram/img-03.jpg";
import shoprry4 from "assets/images/instgram/img-04.jpg";
import shoprry5 from "assets/images/instgram/img-05.jpg";
import shoprry6 from "assets/images/instgram/img-06.jpg";

import tool1 from "assets/images/ecommerce/fast-delivery.png";
import tool2 from "assets/images/ecommerce/returns.png";
import tool3 from "assets/images/ecommerce/guarantee-certificate.png";
import tool4 from "assets/images/ecommerce/24-hours-support.png";



export const aboutus = [
    {
        id:1,
        header: "Code Quality",
        pera1:"Great job on the",
        pera2:"code quality",
        pera: "! Your attention to detail and dedication to producing clean, well-structured, and efficient code is impressive. Keep up the excellent work!",
        img:avatar1,
        cname: "Zebra Fashion",
        depart:"Founder & Owner"
    },
    {
        id:2,
        header: "Design Quality",
        pera: " Awesome! It is a high quality HTML template, I suggest two things, please add Angular version as Default with HTML version and Please try to add LMS part. Thanks ",
        img:avatar2,
        cname: "James Bowen",
        depart:"Web Development"
    },
    {
        id:3,
        header: "Customer Support",
        pera: "High theme quality. Very good support, they spent almost an hour remotely to fix a problem. I hope this theme will have a long term support. Great Admin template comes in handy... :)",
        img:avatar3,
        cname: "Alex Smith",
        depart:"UI/UX Designer"
    },
    {
        id:4,
        header: "Feature Availability",
        pera: " Hello everyone, I would like to suggest here two contents that you could create. Course pages and blog pages. In them you could insert the listing and management of courses.",
        img:avatar4,
        cname: "Ayaan Bowen",
        depart:"Fashion Designer"
    },
    {
        id:5,
        header: "Design Quality",
        pera: "Thank you for supporting CakePHP 4, we have purchased the template because of this support, please push forward more integration. The template is very complete as an admin panel.",
        img:avatar5,
        cname: "Pitch Fashion",
        depart:"Web Designer"
    },
    {
        id:6,
        header: "Customer Support",
        pera: "High theme quality. Very good support, they spent almost an hour remotely to fix a problem. I hope this theme will have a long term support. Great Admin template comes in handy... :)",
        img:avatar3,
        cname: "Alex Smith",
        depart:"UI/UX Designer"
    },
]

export const qualityproduct = [
    {
        id:1,
        sale: "Sale up to 30%",
        img: watch1,
        title:"New Stylish men's wrist watch",
        price: "$241.99",
        offprice: "320.19"
    },
    {
        id:2,
        img: watch2,
        title:"Full Black Fancy Watch",
        price: "$179.65"
    },
    {
        id:3,
        img: watch3,
        title:"Limited Edition Watch For Men",
        price: "$349.49"
    },
    {
        id:4,
        img: watch4,
        title:"Timer Furious Casual Analog Watch",
        price: "$197.45"
    },
    {
        id:5,
        img: watch5,
        title:"Fire-boltt Ninja Pro Max Smartwatch",
        price: "$179.37"
    },
    {
        id:6,
        sale: "Sale up to 15%",
        img: watch6,
        title:"Swiss Track Analog Watch",
        price: "$241.99",
        offprice: "320.19"
    },
    {
        id:7,
        img: watch7,
        title:"Digital Watch in shoppry Mego",
        price: "$147.32"
    },
    {
        id:8,
        img: watch8,
        title:"Swiss Track Analog Watch",
        price: "$357.48"
    }
]

export const shoprry = [
    {
        id:1,
        img: shoprry1,
        content: "One or two brand names can look classy, but too many can detract from the sophistication you're going for.",
        title: "Women Fashion",
        position:"right",
    },
    {
        id:2,
        img: shoprry2,
        content:"A capsule collection is essentially a condensed version of a designer's vision",
        title: "Unique Fashion",
        position:"right",

    },
    {
        id:3,
        img: shoprry3,
        content: "Yours may consist of traditional pieces, such as tops, bottoms, and outerwear, or focus on a single product",
        title: "Men'swear",
        position:"right",

    },
    {
        id:4,
        img: shoprry4,
        content: "Typical sport-specific garments include tracksuits, shorts, T-shirts and polo shirts.",
        title: "Sportwear",
        position:"right",

    },
    {
        id:5,
        img: shoprry5,
        content: "One or two brand names can look classy, but too many can detract from the sophistication you're going for.",
        title: "Women Fashion",
        position:"right",

    },
    {
        id:6,
        img: shoprry6,
        content: "Footwear refers to garments worn on the feet, which typically serves the purpose of protection against adversities.",
        title: "Footwear",
        position:"left",

    },
]


export const servicedata = [
    {
        id:1,
        img: tool1,
        title: "Fast & Secure Delivery",
        caption: "Tell about your service."
    },
    {
        id:1,
        img: tool2,
        title: "2 Days Return Policy",
        caption: "No question ask."
    },
    {
        id:1,
        img: tool3,
        title: "Money Back Guarantee",
        caption: "Within 5 business days"
    },
    {
        id:1,
        img: tool4,
        title: "24 X 7 Service",
        caption: "Online service for customer"
    },
]