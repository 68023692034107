//filte product
import img1 from "assets/images/products/img-1.png";
import img2 from "assets/images/products/img-2.png";
import img3 from "assets/images/products/img-3.png";
import img4 from "assets/images/products/img-4.png";
import img5 from "assets/images/products/img-5.png";
import img6 from "assets/images/products/img-6.png";
import img7 from "assets/images/products/img-7.png";
import img8 from "assets/images/products/img-8.png";
import img9 from "assets/images/products/img-9.png";
import img10 from "assets/images/products/img-10.png";
import img12 from "assets/images/products/img-12.png";
import img15 from "assets/images/products/img-15.png";
import noImage from "assets/images/products/no-img.png";

//catlogDeals
import Eimg5 from "assets/images/ecommerce/img-5.jpg";
import Eimg3 from "assets/images/ecommerce/img-3.jpg";
import Eimg1 from "assets/images/ecommerce/img-1.jpg";

//shopProducDetails
import Product4 from "assets/images/products/img-4.png";
import Product16 from "assets/images/products/img-16.png";
import Product6 from "assets/images/products/img-6.png";
import Product8 from "assets/images/products/img-8.png";

const filterProduct = [
  {
    id: 1,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
  {
    id: 2,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
  {
    id: 3,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
  {
    id: 4,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
  {
    id: 5,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
  {
    id: 6,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
  {
    id: 7,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
  {
    id: 8,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
  {
    id: 9,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
  {
    id: 10,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
  {
    id: 11,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
  {
    id: 12,
    img: noImage,
    presentag: "75%",
    color: ["success", "danger", "secondary"],
    title: "Analytical Equipment",
    description: "Analytical Equipment",
    price: "868.000 ₺",
    deleteproce: "968.000 ₺",
    ratting: 4.7,
    category: "lowtohigh",
    products: "Kids",
    dic: "40",
  },
];

const catlogDeals = [
  {
    id: 1,
    img: Eimg5,
    title: "World's most expensive t-shirt",
    ratting: "4.9",
    price: "$124.99",
    deleteprice: "$354.99",
    days: "2023/12/31",
  },
  {
    id: 2,
    img: Eimg3,
    title: "Best Footwear Collections",
    ratting: "4.3",
    price: "$80.00 ",
    deleteprice: "$180.00",
    days: "2023/12/20",
  },
  {
    id: 3,
    img: Eimg1,
    title: "Elegant Designer Western Dress",
    ratting: "5.0",
    price: "$30.10",
    deleteprice: "$121.99",
    days: "2023/12/22",
  },
];

const shopProducDetails = [
  {
    id: 1,
    img: Product4,
    title: "Sangria Girls Mint Green & Off-White Solid Open Toe Flats",
    discription: "Graphic Print Men & Women Footwear",
    rate: "24.00",
    quantity: "02",
    amount: "48.00",
    bg: "success",
  },
  {
    id: 2,
    img: Product16,
    title: "Rockerz Ear Bluetooth Smart Watch",
    discription: "32.5mm (1.28 Inch) TFT Color Touch Display",
    rate: "160.00",
    quantity: "01",
    amount: "160.00",
    bg: "dark",
  },
  {
    id: 3,
    img: Product6,
    title: "Monte Carlo Sweaters",
    discription: "Graphic Print Men & Women Fashion",
    rate: "244.99",
    quantity: "03",
    amount: "734.97",
    bg: "warning",
  },
  {
    id: 4,
    img: Product8,
    title: "World's most expensive t shirt",
    discription: "Graphic Print Men & Women Fashion",
    rate: "120.30",
    quantity: "02",
    amount: "240.60",
    bg: "secondary",
  },
];

export { filterProduct, catlogDeals, shopProducDetails };
