import products31 from "assets/images/products/img-31.png";
import products33 from "assets/images/products/img-33.png";
import products32 from "assets/images/products/img-32.png";
import products34 from "assets/images/products/img-34.png";

import ecommerce5 from "assets/images/ecommerce/img-5.jpg";
import ecommerce2 from 'assets/images/ecommerce/img-2.jpg';
import ecommerce3 from "assets/images/ecommerce/img-3.jpg";

export const sliderProduct = [
    { id: 1, img: products31 },
    { id: 2, img: products33 },
    { id: 3, img: products32 },
    { id: 4, img: products34 },
    { id: 5, img: products34 },
]


export const descriptionData = [
    { id: 1, thead: "Type", tdata: "Round Neck" },
    { id: 2, thead: "Sleeve", tdata: "Short Sleeve" },
    { id: 3, thead: "Fit", tdata: "Regular" },
    { id: 4, thead: "Sales Package", tdata: "1 T shirt" },
    { id: 5, thead: "Pack of", tdata: "1" },
    { id: 6, thead: "Style Code", tdata: "HYP02" },
    { id: 7, thead: "Neck Type", tdata: "Round Neck" },
    { id: 8, thead: "Ideal For", tdata: "Men" },
    { id: 9, thead: "Size", tdata: "L" },
    { id: 10, thead: "Pattern", tdata: "Solid" },
    { id: 11, thead: "Suitable For", tdata: "Western Wear" },
    { id: 12, thead: "Fabric Care", tdata: "Do not bleach" },
    { id: 13, thead: "Seller", tdata: "Zibra Fashion" },
]


export const productInterestedCard = [
    { id: 1, img: ecommerce5, title: "Women's T-shirts", dic: "Min. 50% off", bg: "secondary", class: "16" },
    { id: 2, img: ecommerce2, title: "Men's Fashion", dic: "Min. 20% off", bg: "primary", class: "16" },
    { id: 3, img: ecommerce3, title: "Women Footwear", dic: "Up to 40-50% off", bg: "info", class: "card-title" }
]

export const productprogress = [
    { id: 1, value: '50.16', color: 'primary', num: '5', progressnum: '2758' },
    { id: 2, value: '29.32', color: 'success', num: '4', progressnum: '1063' },
    { id: 3, value: '18.12', color: 'info', num: '3', progressnum: '997' },
    { id: 4, value: '4.98', color: 'secondary', num: '2', progressnum: '227' },
    { id: 5, value: '7.42', color: 'danger', num: '2', progressnum: '408' },
]