import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

//img
import logolight from "assets/images/logo-light.png";
import logodark from "assets/images/logo-dark.png";
import visa from "assets/images/ecommerce/payment/visa.png";
import discover from "assets/images/ecommerce/payment/discover.png";
import americanexpress from "assets/images/ecommerce/payment/american-express.png";
import paypal from "assets/images/ecommerce/payment/paypal.png";

const Footer = () => {
  return (
    <React.Fragment>
      <section className="section footer-landing pb-0">
        <Container>
          <Row>
            <Col lg={4}>
              <div className="footer-info">
                <Image
                  src={logolight}
                  alt=""
                  height="84"
                  className="logo-light"
                />
                <Image
                  src={logodark}
                  alt=""
                  height="84"
                  className="logo-dark"
                />
                <p className="footer-desc mt-4 mb-2 me-3">
                  1998 yılında kurulan RedoksLAB, uzun süreli iş ortaklığı
                  yaklaşımı ile müşterilerinin üretim verimliliği, ürün kalitesi
                  ve karlılığını artırmak için hızlı, güvenilir analitik
                  cihazlar, yazılımlar, teknik destek, sarf malzeme ve
                  reaktifler sağlıyor.
                </p>

                {/* <div className="footer-social mt-4">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-facebook"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-twitter"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-google"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-reset">
                        <i className="mdi mdi-pinterest"></i>
                      </Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </Col>

            <Col lg={8}>
              <Row className="pl-0 pl-lg-3">
                <Col md={3}>
                  <div className="mt-lg-0 mt-4">
                    <h5 className="footer-title">Kurumsal</h5>
                    <ul className="list-unstyled footer-link mt-3">
                      <li>
                        <Link to="#">Hakkımızda</Link>
                      </li>
                      <li>
                        <Link to="#">Tarihçe</Link>
                      </li>
                      <li>
                        <Link to="#">Aydınlatma Metni</Link>
                      </li>
                      <li>
                        <Link to="#">KVKK</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="mt-lg-0 mt-4">
                    <h5 className="footer-title">Ürünlerimiz</h5>
                    <ul className="list-unstyled footer-link mt-3">
                      <li>
                        <Link to="#">Uygulama Alanları</Link>
                      </li>
                      <li>
                        <Link to="#">Analitik Cihazlar</Link>
                      </li>
                      <li>
                        <Link to="#">Klinik Cihazlar</Link>
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="mt-lg-0 mt-4">
                    <h5 className="footer-title">Hesabım</h5>
                    <ul className="list-unstyled footer-link mt-3">
                      <li>
                        <Link to="#">Kayıt Ol</Link>
                      </li>
                      <li>
                        <Link to="#">Sipariş Yönetimi</Link>
                      </li>
                      <li>
                        <Link to="#">Destek</Link>
                      </li>
                      <li>
                        <Link to="#">Ödeme Yöntemi</Link>
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="mt-lg-0 mt-4">
                    <h5 className="footer-title">İletişim</h5>
                    <ul className="list-unstyled footer-link mt-3">
                      <li>
                        <Link to="#">info@redokslab.com</Link>
                      </li>
                      <li>
                        <Link to="#">satis@redokslab.com</Link>
                      </li>
                      <li>
                        Mustafa Kemal Mahallesi 2076. Sokak No: 8 06530 Çankaya
                        / Ankara
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="footer-border-alt mt-4 align-items-center fs-15">
            <Col sm={6}>
              {new Date().getFullYear()} Redoks. © Tüm Hakları Saklıdır.
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                {/* <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <Link to="#">
                      <Image src={visa} alt="" height="30" />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <Image src={discover} alt="" height="30" />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <Image src={americanexpress} alt="" height="30" />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <Image src={paypal} alt="" height="30" />
                    </Link>
                  </li>
                </ul> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Footer;
